import { Component, NgZone } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

import { Capacitor } from '@capacitor/core';
import { AlertController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { SplashScreen } from '@capacitor/splash-screen';
import * as LiveUpdates from '@capacitor/live-updates';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public platform: Platform,
    private alertCtrl: AlertController,
    private zone: NgZone,
    private router: Router,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.initializeApp();
    this.addListeners();
  }

  addListeners() {
    console.log("app listen - appUrlOpen");
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // FROM https://capacitorjs.com/docs/guides/deep-links#ios-configuration 
        // FROM Apple dev Console :  App ID Prefix=87JRTHH47B / Bundle ID = lk.nxp.fasteleadsng / URL from MagicLink : https://f.nxp.lk
        console.log("appUrlOpen - ", event.url);
        if (event.url.startsWith('https:')) {
          const redirect = event.url.split(".lk").pop();
          console.log("appUrlOpen - https slug=[", redirect, "]");
          if (redirect) {
            // this.router.navigateByUrl(slug);
            console.log("appUrlOpen - window location href => ", redirect);
            window.location.href = redirect;
          } else {
            console.log("event - App URL OPEN no slug => NO REDIRECT");
            // this.router.navigate(['/home']);
          }
        } else if (event.url.startsWith('fasteleads:')) {
          const redirect = event.url.split(":/").pop();
          console.log("appUrlOpen - fasteleads slug=[", redirect, "]");
          if (redirect) {
            console.log("appUrlOpen - window location href => ", redirect);
            window.location.href = redirect;
          } else {
            console.log("appUrlOpen -  App URL OPEN no slug => NO REDIRECT");
          }

        } else {
          console.log("appUrlOpen NO https/fasteleads [" + event.url + "]");
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    console.log("app listen - resume");
    App.addListener('resume', async () => {
      console.log("resume - liveUpdates check...");
      if (Capacitor.getPlatform() !== "web") {
        const url = this.router.url;
        if (!url.startsWith('/event')) {
          console.log("resume - not on event page (do nothing)");
        } else {
          console.log("resume - reload?");
          const result = await LiveUpdates.sync();
          if (result.activeApplicationPathChanged) {
            console.log("resume - update: restart needed!");
            const answer = await this.showAlert();
            if (answer === 'ok') {
              console.log("resume - update: reload!");
              await LiveUpdates.reload();
            } else {
              console.log("resume - update: no reload.");
            }
          } else {
            console.log("resume - no update.");
          }
        }
      } else {
        console.log("resume - web - noLiveUpdates");
      }
    });
  }

  async lockOrientationOnSmartphone() {
    if (this.platform.is('capacitor')) {
      if (this.platform.is('tablet')) {
        console.log(" lockOrientationOnSmartphone - Tablet do nothing!");
      } else {
        const screenOrientationAvailable = Capacitor.isPluginAvailable('ScreenOrientation');
        if (screenOrientationAvailable) {
          console.log("lockOrientationOnSmartphone Lock screen to Portrait");
          await ScreenOrientation.lock({ orientation: 'portrait' }).catch(function (error) { console.log("Impossible de verrouiller l'orientation en mode portrait:", error); });
        }
      }
    } else {
      if (window.screen.orientation) {
        // Verrouiller l'orientation en mode portrait
        // window.screen.orientation.
        // lock("portrait").catch(function(error) {
        // //   console.log("Impossible de verrouiller l'orientation en mode portrait:", error);
        // // });
        console.log("lockOrientationOnSmartphone WEB Screen orientation ", window.screen.orientation, " width=",window.innerWidth);
      }
    }
  }

  async initializeApp() {
    await this.lockOrientationOnSmartphone();
    console.log("initializeApp - start", Capacitor.getPlatform());
    if (Capacitor.getPlatform() !== "web") {
      console.log("app initialize :", (await App.getInfo() ? JSON.stringify(await App.getInfo(), null, 2) : "NOAppInfo"));
    }
    try {
      if (Capacitor.getPlatform() !== "web") {
        console.log("initializeApp - start liveUpdates sync...");
        const result = await LiveUpdates.sync();
        console.log("initializeApp - end   liveUpdates sync! res=", result);
        if (result.activeApplicationPathChanged) {
          console.log("initializeApp - reload!");
          await LiveUpdates.reload();
          await SplashScreen.hide();
        }
        else {
          console.log("initializeApp - splashScreen hide");
          await SplashScreen.hide();
        }
      } else {
        console.log("initializeApp - web no LiveUpdates.");
      }
    } catch (error) {
      console.error(error);
      await SplashScreen.hide();
    }
  }

  async showAlert(): Promise<string | undefined> {
    const alert = await this.alertCtrl.create({
      header: 'Application update',
      cssClass: 'fl-custom-alert',
      message: 'Restart needed to update the application.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('cancel reload');
            // Handle cancel action (if needed)
          },
        },
        {
          text: 'Restart now',
          role: 'ok',
          handler: () => {
            console.log('User clicked OK');
            // Handle OK action (if needed)
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

}
