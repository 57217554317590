import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import * as Sentry from "@sentry/capacitor";
// Use @sentry/angular-ivy for Angular 12+ or `@sentry/angular` from Angular 10 and 11
import * as SentryAngular from "@sentry/angular-ivy";
import { environment } from 'src/environments/environment';
import { CommonNetexpoModule } from './module/common-netexpo/common-netexpo.module';
import pVersion from 'package.json';

console.log("SENTRY init...");
Sentry.init(
  {
    dsn: environment.sentryUrl,
    // added ignoreErrors to ignore isProxied", "ChunkLoadError",
    ignoreErrors:
      ["Failed to fetch", "isProxied", "ChunkLoadError"],
    environment: (environment.production ? "production" : "development"),
    // Set your release version, such as "getsentry@1.0.0"
    release: "fasteleads-ionic@" + pVersion.version,
    // Set your dist version, such as "1"
    dist: pVersion.version,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new SentryAngular.BrowserTracing({
        tracePropagationTargets: ["localhost", "https://f.nxp.lk", "https://fasteleads.nxp.lk"],
        routingInstrumentation: SentryAngular.routingInstrumentation,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);
console.log("SENTRY init:Done!");

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    HttpClientModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonNetexpoModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: SentryAngular.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [SentryAngular.TraceService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

