interface ISOCountry {
  [key: string]: string;
}
export let ISOCountry:ISOCountry []  =  [
    { "AF": "Afghanistan" },
    { "AL": "Albania" },
    { "DZ": "Algeria" },
    { "AS": "American Samoa" },
    { "AD": "Andorra" },
    { "AO": "Angola" },
    { "AI": "Anguilla" },
    { "AG": "Antigua and Barbuda" },
    { "AR": "Argentina" },
    { "AM": "Armenia" },
    { "AW": "Aruba" },
    { "AU": "Australia" },
    { "AT": "Austria" },
    { "AZ": "Azerbaijan" },
    { "BS": "Bahamas" },
    { "BH": "Bahrain" },
    { "BD": "Bangladesh" },
    { "BB": "Barbados" },
    { "BY": "Belarus" },
    { "BE": "Belgium" },
    { "BZ": "Belize" },
    { "BJ": "Benin" },
    { "BM": "Bermuda" },
    { "BT": "Bhutan" },
    { "BO": "Bolivia" },
    { "BA": "Bosnia and Herzegovina" },
    { "BW": "Botswana" },
    { "BR": "Brazil" },
    { "VG": "British Virgin Islands" },
    { "BN": "Brunei Darussalam" },
    { "BG": "Bulgaria" },
    { "BF": "Burkina Faso" },
    { "BI": "Burundi" },
    { "KH": "Cambodia" },
    { "CM": "Cameroon" },
    { "CA": "Canada" },
    { "IC": "Canary Islands (Islas Canarias)" },
    { "CV": "Cape Verde" },
    { "KY": "Cayman Islands" },
    { "CF": "Central African Republic" },
    { "TD": "Chad" },
    { "CL": "Chile" },
    { "CN": "China" },
    { "CX": "Christmas Island" },
    { "CO": "Colombia" },
    { "KM": "Comoros" },
    { "CD": "Congo, Democratic Republic of" },
    { "CG": "Congo, Republic of" },
    { "CK": "Cook Islands" },
    { "CR": "Costa Rica" },
    { "CI": "Cote D'ivoire" },
    { "HR": "Croatia" },
    { "CU": "Cuba" },
    { "CY": "Cyprus" },
    { "CZ": "Czech Republic" },
    { "DK": "Denmark" },
    { "DJ": "Djibouti" },
    { "DM": "Dominica" },
    { "DO": "Dominican Republic" },
    { "TL": "East Timor" },
    { "EC": "Ecuador" },
    { "EG": "Egypt" },
    { "SV": "El Salvador" },
    { "GQ": "Equatorial Guinea" },
    { "ER": "Eritrea" },
    { "EE": "Estonia" },
    { "ET": "Ethiopia" },
    { "FJ": "Fiji" },
    { "FI": "Finland" },
    { "FR": "France" },
    { "GF": "French Guiana" },
    { "PF": "French Polynesia" },
    { "GA": "Gabon" },
    { "GM": "Gambia" },
    { "GE": "Georgia" },
    { "DE": "Germany" },
    { "GH": "Ghana" },
    { "GR": "Greece" },
    { "GD": "Grenada" },
    { "GP": "Guadeloupe" },
    { "GT": "Guatemala" },
    { "GN": "Guinea" },
    { "GW": "Guinea-Bissau" },
    { "GY": "Guyana" },
    { "HT": "Haiti" },
    { "VA": "Holy See (Vatican City State)" },
    { "HN": "Honduras" },
    { "HK": "Hong Kong" },
    { "HU": "Hungary" },
    { "IS": "Iceland" },
    { "IN": "India" },
    { "ID": "Indonesia" },
    { "IR": "Iran, Islamic Republic of" },
    { "IQ": "Iraq" },
    { "IE": "Ireland" },
    { "IL": "Israel" },
    { "IT": "Italy" },
    { "JM": "Jamaica" },
    { "JP": "Japan" },
    { "JO": "Jordan" },
    { "KZ": "Kazakhstan" },
    { "KE": "Kenya" },
    { "KR": "Korea" },
    // added for EANM 2022
    { "KR": "Korea, Rep. of South" },
    { "XK": "Kosovo" },
    { "KW": "Kuwait" },
    { "KG": "Kyrgyzstan" },
    { "LA": "Lao People’s Democratic Republic" },
    { "LV": "Latvia" },
    { "LB": "Lebanon" },
    { "LS": "Lesotho" },
    { "LR": "Liberia" },
    { "LY": "Libyan Arabic Jamahiriya" },
    { "LI": "Liechtenstein" },
    { "LT": "Lithuania" },
    { "LU": "Luxembourg" },
    { "MO": "Macao" },
    { "MK": "Macedonia" },
    { "MG": "Madagascar" },
    { "MW": "Malawi" },
    { "MY": "Malaysia" },
    { "MV": "Maldives" },
    { "ML": "Mali" },
    { "MQ": "Martinique" },
    { "MR": "Mauritania" },
    { "MU": "Mauritius" },
    { "YT": "Mayotte" },
    { "MX": "Mexico" },
    { "MD": "Moldova, Republic of" },
    { "MC": "Monaco" },
    { "MN": "Mongolia" },
    { "ME": "Montenegro" },
    { "MA": "Morocco" },
    { "MZ": "Mozambique" },
    { "MM": "Myanmar" },
    { "NA": "Namibia" },
    { "NP": "Nepal" },
    { "NL": "Netherlands" },
    { "AN": "Netherlands Antilles" },
    { "NC": "New Caledonia" },
    { "NZ": "New Zealand" },
    { "NI": "Nicaragua" },
    { "NE": "Niger" },
    { "NG": "Nigeria" },
    { "NF": "Norfolk Island" },
    { "NO": "Norway" },
    { "OM": "Oman" },
    { "PK": "Pakistan" },
    { "PS": "Palestinian Territory, Occupied" },
    { "PA": "Panama" },
    { "PG": "Papua New Guinea" },
    { "PY": "Paraguay" },
    { "PE": "Peru" },
    { "PH": "Philippines" },
    { "PL": "Poland" },
    { "PT": "Portugal" },
    { "PR": "Puerto Rico" },
    { "QA": "Qatar" },
    { "MT": "Republic of Malta" },
    { "SL": "Republic of Sierra Leone" },
    { "RE": "Reunion" },
    { "RO": "Romania" },
    { "RU": "Russia" },
    { "RW": "Rwanda" },
    { "KN": "Saint kitts and Nevis" },
    { "LC": "Saint Lucia" },
    { "WS": "Samoa" },
    { "SM": "San Marino" },
    { "SA": "Saudi Arabia" },
    { "SN": "Senegal" },
    { "RS": "Serbia" },
    { "SC": "Seychelles" },
    { "ST": "Sao Tome and Principe" },
    { "SG": "Singapore" },
    { "SK": "Slovakia" },
    { "SI": "Slovenia" },
    { "SB": "Solomon Islands" },
    { "SO": "Somalia" },
    { "ZA": "South Africa" },
    { "ES": "Spain" },
    { "LK": "Sri Lanka" },
    { "VC": "St. Vincent and the Grenadines" },
    { "SD": "Sudan" },
    { "SR": "Suriname" },
    { "SZ": "Swaziland" },
    { "SE": "Sweden" },
    { "CH": "Switzerland" },
    { "SY": "Syrian Arab Republic" },
    { "TW": "Taiwan, Province of China" },
    { "TJ": "Tajikistan" },
    { "TZ": "Tanzania, United Republic of" },
    { "TH": "Thailand" },
    { "TG": "Togo" },
    { "TO": "Tonga" },
    { "TT": "Trinidad and Tobago" },
    { "TN": "Tunisia" },
    { "TR": "Turkey" },
    // added for EANM 2022
    // { "TR": "Türkiye"},
    // { "TR": "TÃ¼rkiye"},
    { "TM": "Turkmenistan" },
    { "TC": "Turks and Caicos Islands" },
    { "UG": "Uganda" },
    { "UA": "Ukraine" },
    { "AE": "United Arab Emirates" },
    { "GB": "United Kingdom" },
    { "US": "United States" },
    // added for EANM 2022
    // { "US": "United States of America"},
    { "UY": "Uruguay" },
    { "VI": "US Virgin Islands" },
    { "UZ": "Uzbekistan" },
    { "VU": "Vanuatu" },
    { "VE": "Venezuela" },
    { "VN": "Vietnam" },
    { "YE": "Yemen" },
    { "ZM": "Zambia" },
    { "ZW": "Zimbabwe" }
  ];
  