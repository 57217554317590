import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TokenInterceptor } from './token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'lead',
    loadChildren: () => import('./lead/lead.module').then( m => m.LeadPageModule)
  },
  {
    path: 'lead/:id',
    loadChildren: () => import('./lead/lead.module').then( m => m.LeadPageModule)
  },
  {
    path: 'createAccess',
    loadChildren: () => import('./create-access/create-access.module').then( m => m.CreateAccessPageModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'manual',
    loadChildren: () => import('./manual/manual.module').then( m => m.ManualPageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'gdpr',
    loadChildren: () => import('./gdpr/gdpr.module').then( m => m.GdprPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'thanks',
    loadChildren: () => import('./thanks/thanks.module').then( m => m.ThanksPageModule)
  },
  {
    path: 'test-scroll',
    loadChildren: () => import('./country-picker/country-picker.module').then( m => m.CountryPickerPageModule)
  },

  {
    path: 'lead-taker',
    loadChildren: () => import('./lead-taker/lead-taker.module').then( m => m.LeadTakerPageModule)
  },
  {
    path: 'draft',
    loadChildren: () => import('./draft/draft.module').then( m => m.DraftPageModule)
  },
  {

    path: 'group-consent',
    loadChildren: () => import('./group-consent/group-consent.module').then( m => m.GroupConsentPageModule)
},{
    path: 'update',
    loadChildren: () => import('./update/update.module').then( m => m.UpdatePageModule)

  },
  {
    path: 'nda',
    loadChildren: () => import('./nda/nda.module').then( m => m.NdaPageModule)
  },
  {
    path: 'septimus',
    loadChildren: () => import('./nda/nda.module').then( m => m.NdaPageModule)
  },
  {
    path: 'create-event',
    loadChildren: () => import('./create-event/create-event.module').then( m => m.CreateEventPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  // providers: [TokenInterceptor],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }]
})
export class AppRoutingModule { }
